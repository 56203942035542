.error {
  color: #f32b2b !important;
  font-weight: 700 !important;
}

.error-input {
  border-color: #f32b2b !important;
}

.notify-button {
  border: none;
  background: transparent;
  color: #bdff00;
}

.go-back-button {
  background-color: #fff;
  border-radius: 100%;
  pad: 42px;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;
    margin-top: -5px;
  }
}

.help-box {
  padding-bottom: 100px !important;
}

.toggle-switch {
  float: right;
  display: flex;
  top: 0px;
  margin-top: -40px;
  margin-bottom: 20px;
  position: relative;
  width: 60px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 30px;
      padding: 0;
      line-height: 31px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }

    &:before {
      content: "PM";
      text-transform: uppercase;
      padding-left: 12px;
      background-color: #000;
      font-size: 0.7em;
      color: #fff;
    }
  }

  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;

    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }

  &-inner:after {
    content: "AM";
    text-transform: uppercase;
    padding-right: 12px;
    background-color: #000;
    font-size: 0.7em;
    color: #fff;
    text-align: right;
  }

  &-switch {
    display: block;
    width: 18px;
    height: 18px;
    margin: 6px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 29px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked+&-label {
    .toggle-switch-inner {
      margin-left: 0;
    }

    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.calendar-style {
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #f6f6f6;

  span {
    font-weight: 600;
    font-size: 0.9em;
  }

  button {
    color: #b0abac;
  }

  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__label__labelText {
    color: #000;
  }

  .react-calendar__tile--now {
    color: #fff !important;
    background: #b0abac;
    border-radius: 5px;
  }

  .react-calendar__navigation__prev2-button {
    display: none !important;
  }

  .react-calendar__tile--active {
    border-radius: 100%;
    padding: 1px;
    background-color: rgb(20, 78, 216);
    color: #fff;
  }
}

.ant-steps {
  display: flex !important;
  justify-content: center;
  width: 100% !important;

  .ant-steps-item {
    width: 100px;
  }

  .ant-steps-icon {
    top: -4px !important;
  }

  .ant-steps-item-icon {
    width: 15px !important;
    height: 15px !important;
    border-radius: 100% !important;
  }

  .ant-steps-icon-dot {
    background-color: #1677ff;
  }

  .ant-steps-item-finish {
    .ant-steps-icon-dot {
      background-color: #74c974 !important;
    }
  }
}

:where(.css-dev-only-do-not-override-diro6f).ant-steps.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
:where(.css-dev-only-do-not-override-diro6f).ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  border-radius: 100px !important;
}

.waves-pattern {
  margin-top: -60px !important;

  @media (min-width: 1000px) {
    margin-top: -150px !important;

    height: 100px svg {
      height: 100px;
    }
  }
}

a:hover {
  text-decoration: none !important;
}

.language-picker {
  border: thin solid #fff !important;
  border-radius: 5px;
  div{
    padding-bottom: 0px !important;
  }

  span {
    color: #fff;
  }

  ul {
    background-color: #fff;
    border: thin solid #fff !important;

    li {
      span {
        color: #000 !important;
      }
    }
  }

  ::after {
    border-top: 5px solid #fff;
  }
}

.language-picker-wizzard{
  margin-top: 0px !important;
}

